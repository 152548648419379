import { Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RouterEvent,
} from '@angular/router';
import { UtilService } from '@app/utils/util.service';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale } from 'ngx-bootstrap/locale';
import { LoadingService } from '../core/services/loading/loading.service';

defineLocale('de', deLocale);

@Component({
    selector: 'dd-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    public loading = true;
    public firstLoading = true;
    public loadingRoute = false;

    constructor(
        private router: Router,
        private loadingService: LoadingService,
        private utilService: UtilService,
        private translateService: TranslateService,
        private ngSelectConfig: NgSelectConfig,
    ) {
        this.translateService.addLangs(['de', 'en']);
        this.translateService.setDefaultLang('de');
        this.translateService.use(localStorage.getItem('language') ?? 'de');
        this.translateService.onLangChange
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                this.ngSelectConfig.notFoundText =
                    this.translateService.instant('GENERIC.EMPTY_MESSAGE');
            });
    }

    public ngOnInit(): void {
        this.router.events.subscribe((event) => {
            this.navigationInterceptor(event as RouterEvent);
        });
        this.loadingService.loadingObservable.subscribe((show) => {
            setTimeout(() => {
                this.loading = show;
                this.firstLoading = this.loadingService.firstLoading;
            });
        });
    }

    // Shows and hides the loading spinner during RouterEvent changes
    public navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.loadingRoute = true;
        }
        if (event instanceof NavigationEnd) {
            this.loadingRoute = false;
        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.loadingRoute = false;
        }
        if (event instanceof NavigationError) {
            this.loadingRoute = false;
        }
        this.utilService.scrollTop();
    }
}
