import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
    AUTH_SERVICE,
    PROTECTED_FALLBACK_PAGE_URI,
    PUBLIC_FALLBACK_PAGE_URI,
} from 'ngx-auth';
import { AppComponent } from './app.component';
import { PortalRoutes } from './app.routes';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from '@core/core.module';
import { AuthenticationService } from '@core/services/auth/auth.service';
import { ApiInterceptor } from '@core/services/http/api.interceptor';
import { PublicModule } from '@public/public.module';

registerLocaleData(localeDe, 'de');

import {
    MAT_PAGINATOR_DEFAULT_OPTIONS,
    MatPaginatorIntl,
} from '@angular/material/paginator';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS } from '@angular/material/progress-spinner';
import { WebpackTranslateLoader } from '@app/i18n/webpack-translate.loader';
import { RedirectService } from '@core/services/redirect/redirect.service';
import { MODULE } from '@core/tokens/module.token';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MatPaginatorIntlDe } from '@shared/table-utilities/mat-pagination-intl';

@NgModule({
    declarations: [AppComponent],
    imports: [
        CoreModule.forRoot(),
        BrowserModule,
        PortalRoutes,
        PublicModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        MatProgressBarModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader,
            },
        }),
    ],
    providers: [
        { provide: MODULE, useValue: '' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: 'de',
        },
        {
            provide: AUTH_SERVICE,
            useClass: AuthenticationService,
        },
        { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/' },
        { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/' },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe },
        {
            provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
            useValue: { diameter: 32, strokeWidth: 3, color: 'accent' },
        },
        {
            provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
            useValue: {
                pageSize: 20,
                pageSizeOptions: [20, 50, 100],
                showFirstLastButtons: true,
            },
        },
        RedirectService,
    ],
    bootstrap: [AppComponent],
})
export class PortalAppModule {}
