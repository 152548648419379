import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HEX_MATCH2 } from '@core/constants/regex';
import { LoginMode } from '@core/services/auth/auth-settings.service';
import { SkipModuleUrl } from '@core/services/http/api.interceptor';
import { MODULE } from '@core/tokens/module.token';
import { Observable } from 'rxjs';

export interface FileSizeResponse {
    valid: boolean;
    maxFileUpload: number | string;
}

export interface ListHistory {
    page: number;
    type: string;
}

@Injectable({
    providedIn: 'root',
})
export class UtilService {
    private lastListHistory: ListHistory;
    private previousUrl: string;
    private currentUrl: string;

    constructor(
        private readonly httpClient: HttpClient,
        @Inject(MODULE) private module: LoginMode,
        private router: Router,
    ) {
        this.currentUrl = this.router.url;
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            }
        });
    }

    public saveListHistory(page: number, type: string): void {
        this.lastListHistory = <ListHistory>{
            page: page,
            type: type,
        };
    }

    public getListPageHistory(type: string, nextPage: number): number {
        if (!type) {
            return nextPage;
        }
        const prevPage = this.getPreviousUrl();
        if (
            prevPage?.includes(`${type}/`) &&
            !prevPage.includes(`/new`) &&
            this.lastListHistory &&
            this.lastListHistory.page > 0
        ) {
            const page = this.lastListHistory.page;
            this.previousUrl = this.router.url;
            this.lastListHistory = null;
            return page;
        } else {
            this.lastListHistory = null;
        }
        return nextPage;
    }

    private getPreviousUrl(): string {
        return this.previousUrl;
    }

    public isValidFileSize(size: number): Observable<any> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `utils/is-valid-file-size`;
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient.post(url, { size: size }, { headers });
    }

    public scrollTop(): void {
        const field = document.querySelector('.mat-sidenav-content');
        if (field) {
            field.scroll(0, 0);
        }
    }

    public scrollToElement(
        element: Element,
        position: ScrollLogicalPosition = 'center',
    ): void {
        element.scrollIntoView({
            block: position,
            behavior: 'smooth',
        });
    }

    private luminanace(r, g, b): number {
        const a = [r, g, b].map(function (v) {
            v /= 255;
            return v <= 0.03928
                ? v / 12.92
                : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    }

    public contrast(rgb1: number[], rgb2: number[]): number {
        const lum1 = this.luminanace(rgb1[0], rgb1[1], rgb1[2]);
        const lum2 = this.luminanace(rgb2[0], rgb2[1], rgb2[2]);
        const brightest = Math.max(lum1, lum2);
        const darkest = Math.min(lum1, lum2);
        return (brightest + 0.05) / (darkest + 0.05);
    }

    public isRecomendedContrast(value: number, fontsizepx?: number): boolean {
        let limit = 3.3;
        if (
            fontsizepx !== null &&
            fontsizepx !== undefined &&
            fontsizepx >= 20
        ) {
            limit = 2.8;
        }
        return value > limit;
    }

    public hexToRgb(hex: string): number[] {
        const result = HEX_MATCH2.exec(hex);
        return result
            ? [
                  parseInt(result[1], 16),
                  parseInt(result[2], 16),
                  parseInt(result[3], 16),
              ]
            : null;
    }
}
