import { environment } from './environments/environment';
import { PortalAppModule } from './portal-app/app.module';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

declare global {
    interface Window {
        _paq: any;
    }
}

if (environment.production) {
    enableProdMode();

    const _paq = (window._paq = window._paq || []);
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function (): void {
        const u = '//matomo.meindienstrad.services/';
        _paq.push(['setTrackerUrl', u + 'matomo.php']);
        _paq.push(['setSiteId', '1']);
        const d = document,
            g = d.createElement('script'),
            s = d.getElementsByTagName('script')[0];
        g.async = true;
        g.src = u + 'matomo.js';
        s.parentNode.insertBefore(g, s);
    })();
}

platformBrowserDynamic()
    .bootstrapModule(PortalAppModule)
    .catch((err) => console.error(err));
